import { useStore } from 'vuex';
import axios from '@/api/request';
function useLocation() {
  const store = useStore();
  const geoLocation = computed(() => {
    return store.state.user.geoLocation || 'All';
  });
  const geoLocations = ref([]);
  function getGeoLocationInfo(latitude, longitude) {
    return axios.get('/home/LocateState', {
      params: {
        lng: longitude,
        lat: latitude
      },
      isNotLoading: true
    });
  }

  // Obtain the user's geographical location
  function getGeoLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          getGeoLocationInfo(latitude, longitude).then(res => {
            if (res && res.data && res.data.state) {
              store.commit('user/setUserGeoLocation', res.data.state);
              resolve(res.data.state);
            }
          });
        }, function (error) {
          console.error(error);
          reject('User refuses to obtain geographic location.');
        });
      } else {
        reject(new Error('The device does not support obtaining geographical location.'));
      }
    });
  }
  function setGeoLocation(geoLocationInfo) {
    store.commit('user/setUserGeoLocation', geoLocationInfo);
  }
  function getGeoLocations(data) {
    axios.get('/home/StateList', {
      params: data,
      isNotLoading: true
    }).then(res => {
      if (res && res.data) {
        geoLocations.value = res.data;
      }
    });
  }
  return {
    geoLocation,
    geoLocations,
    getGeoLocation,
    setGeoLocation,
    getGeoLocations
  };
}
export default useLocation;